import Author from '@/assets/js/Author.class'
import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'
export default [
  {
    name: 'analytics',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/telegram1.svg',
    link: 'https://t.me/toptradingview',
    linkI18n: '',
    show: false,
  },
  {
    name: 'academy',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/telegram2.svg',
    link: 'https://t.me/tt_academy',
    linkI18n: '',
    show: false,
  },
  {
    name: 'defilog',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/telegram3.svg',
    link: 'https://t.me/DeFiLoG',
    linkI18n: '',
    show: false,
  },

  {
    name: 'TTtools',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/telegram4.svg',
    svg: Author.socialNetworkSvg('telegram'),
    link: '',
    linkI18n: 'aboutUs.tgTTTools',
    show: true,
  },
  {
    name: 'achour',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/achour.svg',
    link: 'https://anchor.fm/dakacrypto',
    linkI18n: '',
    show: false,
  },
  {
    name: 'vc',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/vc.svg',
    link: 'https://vc.ru/u/642470-tmuslim',
    linkI18n: '',
    show: false,
  },
  {
    name: 'zen',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/zen.svg',
    link: 'https://zen.yandex.ru/id/60127ba261648604d19a9648',
    linkI18n: '',
    show: false,
  },
  {
    name: 'youtube',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/youtube.svg',
    svg: Author.socialNetworkSvg('youtube'),
    link: '',
    linkI18n: 'aboutUs.youtube_link',
    show: true,
  },
  {
    name: 'facebook',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/facebook.svg',
    svg: Author.socialNetworkSvg('facebook'),
    link: '',
    linkI18n: 'aboutUs.fb_link',
    show: true,
  },
  {
    name: 'medium',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/medium.svg',
    link: 'https://medium.com/@toptradersico',
    linkI18n: '',
    show: false,
  },
  {
    name: 'instagram',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/instagram.svg',
    svg: Author.socialNetworkSvg('instagram'),
    link: '',
    linkI18n: 'aboutUs.inst_link',
    show: true,
  },
  {
    name: 'twitter',
    logo: BUILD_PUBLIC_PATH + '/img/svg/media-links/twitter.svg',
    svg: Author.socialNetworkSvg('x'),
    link: '',
    linkI18n: 'aboutUs.twitter_link',
    show: true,
  },
]
