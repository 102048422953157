var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 d-flex flex-column" },
    [
      _c("h1", { staticClass: "title" }, [
        _vm._v(" " + _vm._s(_vm.$t("aboutUs.title")) + " "),
      ]),
      _c(
        "CCard",
        { staticClass: "ccard mt-2 mb-0 overflow-hidden flex-grow-1" },
        [
          _c(
            "CCardBody",
            { staticClass: "ccard-body overflow-auto" },
            [
              _c(
                "CRow",
                { staticClass: "crow position-relative" },
                [
                  _c(
                    "CCol",
                    {
                      staticClass: "partners-media",
                      attrs: { col: "12", xl: "5" },
                    },
                    [
                      _c(
                        "CCard",
                        {
                          staticClass: "partner-card shadow-none",
                          attrs: { color: "second-card-bg" },
                        },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: "ccard-body" },
                            [
                              _c(
                                "h3",
                                { staticClass: "partner-title text-white" },
                                [_vm._v(_vm._s(_vm.$t("aboutUs.partners")))]
                              ),
                              _c(
                                "CRow",
                                [
                                  _vm._l(
                                    _vm.partnersData,
                                    function (partner, i) {
                                      return [
                                        partner.show
                                          ? _c(
                                              "CCol",
                                              {
                                                key: i,
                                                staticClass:
                                                  "partner _mb justify-content-center",
                                                attrs: {
                                                  col: "6",
                                                  md: "3",
                                                  xl: "4",
                                                },
                                              },
                                              [
                                                _c(
                                                  "CLink",
                                                  {
                                                    attrs: {
                                                      target: "_blank",
                                                      href: partner.link,
                                                    },
                                                  },
                                                  [
                                                    partner.svg
                                                      ? _c("span", {
                                                          staticStyle: {
                                                            color:
                                                              "var(--white)",
                                                          },
                                                          domProps: {
                                                            innerHTML: _vm._s(
                                                              partner.logo.icon
                                                            ),
                                                          },
                                                        })
                                                      : _c("img", {
                                                          attrs: {
                                                            src: partner.logo,
                                                            alt: partner.name,
                                                          },
                                                        }),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    }
                                  ),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        {
                          staticClass: "media-card shadow-none",
                          attrs: { color: "second-card-bg" },
                        },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: "ccard-body" },
                            [
                              _c(
                                "h3",
                                { staticClass: "media-title text-white" },
                                [_vm._v(_vm._s(_vm.$t("aboutUs.media")))]
                              ),
                              _c(
                                "CRow",
                                [
                                  _vm._l(_vm.mediaData, function (media, i) {
                                    return [
                                      media.show
                                        ? _c(
                                            "CCol",
                                            {
                                              key: i,
                                              staticClass: "media-link",
                                              attrs: {
                                                col: "2",
                                                lg: "1",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c(
                                                "a",
                                                {
                                                  attrs: {
                                                    href:
                                                      media.link ||
                                                      _vm.$t(media.linkI18n),
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  media.svg
                                                    ? _c("span", {
                                                        domProps: {
                                                          innerHTML: _vm._s(
                                                            media.svg
                                                          ),
                                                        },
                                                      })
                                                    : media.logo
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: media.logo,
                                                          alt: media.name,
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "CCard",
                        {
                          staticClass: "media-card shadow-none last",
                          attrs: { color: "second-card-bg" },
                        },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: "ccard-body" },
                            [
                              _c(
                                "h3",
                                { staticClass: "media-title text-white mb-2" },
                                [_vm._v(_vm._s(_vm.$t("aboutUs.contacts")))]
                              ),
                              _c(
                                "CRow",
                                [
                                  _c(
                                    "CCol",
                                    {
                                      staticClass: "d-flex flex-column",
                                      attrs: { col: "6" },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass: "mb-1",
                                          attrs: {
                                            href: "mailto:info@cvizor.com",
                                          },
                                        },
                                        [_vm._v("info@cvizor.com")]
                                      ),
                                      _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "mailto:support@cvizor.com",
                                          },
                                        },
                                        [_vm._v("support@cvizor.com")]
                                      ),
                                    ]
                                  ),
                                  _c("CCol", { attrs: { col: "6" } }, [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item mb-2 d-flex",
                                        attrs: {
                                          href: _vm.$t("aboutUs.tgTTToolschat"),
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                              },
                                            },
                                            [
                                              _c("g", [
                                                _c("path", {
                                                  attrs: {
                                                    "fill-rule": "evenodd",
                                                    "clip-rule": "evenodd",
                                                    d: "M9.61976 14.7779L9.61873 14.7772L9.61862 14.7789L9.27447 19.935C9.76651 19.935 9.99137 19.7161 10.2631 19.4514C10.2707 19.4441 10.2784 19.4366 10.286 19.4292L12.7154 17.0669L17.7717 20.8025C18.7021 21.3159 19.3736 21.0501 19.6053 19.9387L22.924 4.29966C23.2638 2.93741 22.4048 2.31955 21.5147 2.72363L2.0272 10.2379C0.696996 10.7715 0.704751 11.5136 1.78473 11.8443L6.78567 13.4051L18.3634 6.10091C18.9099 5.76948 19.4116 5.94766 18.9998 6.31307L9.61976 14.7779Z",
                                                  },
                                                }),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c("span", { staticClass: "title" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("aboutUs.chatLink"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "item mb-2 d-flex",
                                        attrs: {
                                          href: _vm.$t("aboutUs.tgTTTools"),
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c(
                                            "svg",
                                            {
                                              attrs: {
                                                xmlns:
                                                  "http://www.w3.org/2000/svg",
                                                viewBox: "0 0 24 24",
                                                fill: "none",
                                              },
                                            },
                                            [
                                              _c("g", [
                                                _c("path", {
                                                  attrs: {
                                                    "fill-rule": "evenodd",
                                                    "clip-rule": "evenodd",
                                                    d: "M9.61976 14.7779L9.61873 14.7772L9.61862 14.7789L9.27447 19.935C9.76651 19.935 9.99137 19.7161 10.2631 19.4514C10.2707 19.4441 10.2784 19.4366 10.286 19.4292L12.7154 17.0669L17.7717 20.8025C18.7021 21.3159 19.3736 21.0501 19.6053 19.9387L22.924 4.29966C23.2638 2.93741 22.4048 2.31955 21.5147 2.72363L2.0272 10.2379C0.696996 10.7715 0.704751 11.5136 1.78473 11.8443L6.78567 13.4051L18.3634 6.10091C18.9099 5.76948 19.4116 5.94766 18.9998 6.31307L9.61976 14.7779Z",
                                                  },
                                                }),
                                              ]),
                                            ]
                                          ),
                                        ]),
                                        _c("span", { staticClass: "title" }, [
                                          _vm._v(
                                            _vm._s(_vm.$t("aboutUs.groupLink"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("hr", { staticClass: "divider" }),
                  _c(
                    "CCol",
                    { attrs: { col: "12", xl: { size: 6, offset: 1 } } },
                    [
                      _c(
                        "CCard",
                        {
                          staticClass: "partner-card form-card shadow-none",
                          attrs: { color: "second-card-bg" },
                        },
                        [
                          _c(
                            "CCardBody",
                            { staticClass: "ccard-body" },
                            [
                              _c(
                                "h3",
                                { staticClass: "partner-title text-white" },
                                [_vm._v(_vm._s(_vm.$t("aboutUs.writeToUs")))]
                              ),
                              _c(
                                "CForm",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.validate.apply(null, arguments)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        { attrs: { col: "6" } },
                                        [
                                          _c("CInput", {
                                            staticClass: "cinput",
                                            attrs: {
                                              disabled: _vm.loadingRequest,
                                              placeholder: `${_vm.$t(
                                                "aboutUs.form.name"
                                              )}`,
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.name,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "name", $$v)
                                              },
                                              expression: "form.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "CCol",
                                        { attrs: { col: "6" } },
                                        [
                                          _c("CInput", {
                                            staticClass: "cinput",
                                            attrs: {
                                              disabled: _vm.loadingRequest,
                                              placeholder: `${_vm.$t(
                                                "aboutUs.form.tg"
                                              )}`,
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.tg,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "tg", $$v)
                                              },
                                              expression: "form.tg",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        [
                                          _c("CInput", {
                                            staticClass: "cinput",
                                            attrs: {
                                              disabled: _vm.loadingRequest,
                                              placeholder: `${_vm.$t(
                                                "aboutUs.form.email"
                                              )}`,
                                              type: "email",
                                            },
                                            model: {
                                              value: _vm.form.email,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "email", $$v)
                                              },
                                              expression: "form.email",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        [
                                          _c("CInput", {
                                            staticClass: "cinput",
                                            attrs: {
                                              disabled: _vm.loadingRequest,
                                              placeholder: `${_vm.$t(
                                                "aboutUs.form.subject"
                                              )}`,
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.subject,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "subject",
                                                  $$v
                                                )
                                              },
                                              expression: "form.subject",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "CRow",
                                    [
                                      _c(
                                        "CCol",
                                        [
                                          _c("CTextarea", {
                                            staticClass: "cinput",
                                            attrs: {
                                              disabled: _vm.loadingRequest,
                                              rows: "5",
                                              placeholder: `${_vm.$t(
                                                "aboutUs.form.question"
                                              )}`,
                                            },
                                            model: {
                                              value: _vm.form.question,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "question",
                                                  $$v
                                                )
                                              },
                                              expression: "form.question",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "form-footer" },
                                    [
                                      _vm.isGuest
                                        ? _c("VueRecaptcha", {
                                            ref: "recaptcha",
                                            attrs: {
                                              size: "invisible",
                                              sitekey: _vm.RECAPTCHA_SECRET_KEY,
                                            },
                                            on: {
                                              verify: _vm.sendFeedback,
                                              expired: _vm.onCaptchaExpired,
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "CLoadingButtonCustom",
                                        {
                                          staticClass: "button",
                                          attrs: {
                                            color: "primary",
                                            loading: _vm.loadingRequest,
                                            disabled: _vm.loadingRequest,
                                            type: "submit",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.$t("aboutUs.form.send")
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _c("div", {
                                        staticClass: "info",
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.$t("aboutUs.form.info")
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }