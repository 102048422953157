export const icon = `<svg width="128" height="32" viewBox="0 0 192 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M47.5963 25.0244H51.2985V10.5199H56.1418V7.02051H42.8037V10.5199H47.5963V25.0244Z" fill="currentColor"/>
<path d="M56.147 25.0252H59.621V18.9648C59.621 16.8601 60.7114 15.5922 62.3596 15.5922C62.9429 15.5922 63.3993 15.6936 63.9318 15.8711V12.3464C63.5007 12.2196 63.1457 12.1943 62.7654 12.1943C61.3453 12.1943 60.0521 13.1325 59.621 14.375V12.3464H56.147V25.0252Z" fill="currentColor"/>
<path d="M70.5997 25.304C72.3493 25.304 73.6679 24.4165 74.2512 23.6051V25.0251H77.7251V12.3463H74.2512V13.7664C73.6679 12.9549 72.3493 12.0674 70.5997 12.0674C67.2271 12.0674 64.6406 15.1103 64.6406 18.6857C64.6406 22.2611 67.2271 25.304 70.5997 25.304ZM71.335 22.1344C69.4079 22.1344 68.14 20.689 68.14 18.6857C68.14 16.6825 69.4079 15.2371 71.335 15.2371C73.2622 15.2371 74.5301 16.6825 74.5301 18.6857C74.5301 20.689 73.2622 22.1344 71.335 22.1344Z" fill="currentColor"/>
<path d="M85.9967 25.3039C87.7463 25.3039 89.0649 24.4164 89.6481 23.605V25.025H93.1221V6.00684H89.6481V13.7663C89.0649 12.9548 87.7463 12.0673 85.9967 12.0673C82.6241 12.0673 80.0376 15.1102 80.0376 18.6856C80.0376 22.261 82.6241 25.3039 85.9967 25.3039ZM86.732 22.1343C84.8049 22.1343 83.537 20.6889 83.537 18.6856C83.537 16.6824 84.8049 15.237 86.732 15.237C88.6592 15.237 89.9271 16.6824 89.9271 18.6856C89.9271 20.6889 88.6592 22.1343 86.732 22.1343Z" fill="currentColor"/>
<path d="M97.9703 10.2667C99.2128 10.2667 100.202 9.27776 100.202 8.0606C100.202 6.84344 99.2128 5.85449 97.9703 5.85449C96.7785 5.85449 95.7642 6.84344 95.7642 8.0606C95.7642 9.27776 96.7785 10.2667 97.9703 10.2667ZM96.246 25.0248H99.72V12.346H96.246V25.0248Z" fill="currentColor"/>
<path d="M102.802 25.0251H106.276V18.8379C106.276 16.4035 107.468 15.2371 109.091 15.2371C110.536 15.2371 111.322 16.3528 111.322 18.1786V25.0251H114.796V17.5953C114.796 14.2989 112.97 12.0674 109.902 12.0674C108.254 12.0674 106.961 12.7774 106.276 13.7917V12.3463H102.802V25.0251Z" fill="currentColor"/>
<path d="M123.637 21.7286C121.887 21.7286 120.416 20.41 120.416 18.4829C120.416 16.5303 121.887 15.2371 123.637 15.2371C125.386 15.2371 126.857 16.5303 126.857 18.4829C126.857 20.41 125.386 21.7286 123.637 21.7286ZM123.231 30.5784C127.111 30.5784 130.052 28.6005 130.052 24.0362V12.3463H126.578V13.7664C125.894 12.7774 124.524 12.0674 122.901 12.0674C119.579 12.0674 116.917 14.9074 116.917 18.4829C116.917 22.0329 119.579 24.873 122.901 24.873C124.524 24.873 125.894 24.1376 126.578 23.1994V24.0615C126.578 26.0901 125.234 27.5355 123.18 27.5355C121.76 27.5355 120.34 27.0537 119.224 25.9633L117.323 28.5498C118.768 29.9445 121.025 30.5784 123.231 30.5784Z" fill="currentColor"/>
<path d="M137.397 25.0244H140.668L148.124 7.02051H144.066L139.046 19.5218L133.949 7.02051H129.968L137.397 25.0244Z" fill="currentColor"/>
<path d="M151.311 10.2667C152.554 10.2667 153.543 9.27776 153.543 8.0606C153.543 6.84344 152.554 5.85449 151.311 5.85449C150.119 5.85449 149.105 6.84344 149.105 8.0606C149.105 9.27776 150.119 10.2667 151.311 10.2667ZM149.587 25.0248H153.061V12.346H149.587V25.0248Z" fill="currentColor"/>
<path d="M162.051 25.304C164.714 25.304 166.743 24.1883 167.934 22.464L165.348 20.5368C164.765 21.4243 163.674 22.1344 162.077 22.1344C160.403 22.1344 158.907 21.1961 158.704 19.4972H168.188C168.264 18.9647 168.239 18.6096 168.239 18.3054C168.239 14.1721 165.323 12.0674 162.001 12.0674C158.146 12.0674 155.332 14.9328 155.332 18.6857C155.332 22.6922 158.273 25.304 162.051 25.304ZM158.831 17.1389C159.161 15.5921 160.606 14.9328 161.899 14.9328C163.193 14.9328 164.46 15.6175 164.765 17.1389H158.831Z" fill="currentColor"/>
<path d="M181.254 25.0245H184.702L188.886 12.3457H185.159L182.775 20.4602L180.189 12.3457H177.526L174.965 20.4602L172.556 12.3457H168.854L173.063 25.0245H176.512L178.87 17.823L181.254 25.0245Z" fill="currentColor"/>
<path d="M14 25H7V14H0V7H14V25Z" fill="currentColor"/>
<path d="M28 25H20L27.5 7H35.5L28 25Z" fill="currentColor"/>
<path d="M20 15C22.2091 15 24 13.2091 24 11C24 8.79086 22.2091 7 20 7C17.7909 7 16 8.79086 16 11C16 13.2091 17.7909 15 20 15Z" fill="currentColor"/>
</svg>
`
