import {BUILD_PUBLIC_PATH} from '@/assets/js/constants'

export default [
  {
    name: 'binance',
    logo: BUILD_PUBLIC_PATH + '/img/png/binance.svg',
    link: 'https://binance.cvizor.com/',
    show: true,
  },
  {
    name: 'ftx',
    logo: BUILD_PUBLIC_PATH + '/img/png/ftx.png',
    link: 'https://ftx.com/ru/?utm_source=toptradersacademy',
    show: false,
  },
  {
    name: 'tradingview',
    logo: require('@/assets/icons/tradingview'),
    svg: true,
    link: 'https://tv.cvizor.com/',
    show: true,
  },
  {
    name: 'exmo',
    logo: BUILD_PUBLIC_PATH + '/img/png/exmo.png',
    link: 'https://exmo.com/ru?utm_source=partnership_toptraders',
    show: false,
  },
  {
    name: 'bybit',
    logo: BUILD_PUBLIC_PATH + '/img/png/bybit.png',
    link: 'https://www.bybit.com/en-US/?utm_source=toptradersacademy',
    show: false,
  },
  {
    name: '3commas',
    logo: BUILD_PUBLIC_PATH + '/img/png/3commas.png',
    link: 'https://3commas.io/ru?utm_source=partnership_toptraders',
    show: false,
  },
  {
    name: 'okex',
    logo: BUILD_PUBLIC_PATH + '/img/png/okex.png',
    link: 'https://www.okex.com/ru?utm_source=toptradersacademy',
    show: false,
  },
  {
    name: 'aax',
    logo: BUILD_PUBLIC_PATH + '/img/png/aax.png',
    link: 'https://www.aax.com/en-US/invite/sign-up/?inviteCode=Anl0QHVvgPqZ&utm_source=ru_toptraders&utm_medium=telegram&utm_campaign=post_2',
    show: false,
  },
  {
    name: 'ascendex',
    logo: BUILD_PUBLIC_PATH + '/img/png/ascendex.png',
    link: 'https://ascendex.com/ru/register?inviteCode=4KGQ8RZD',
    show: false,
  },
  {
    name: 'kucoin',
    logo: BUILD_PUBLIC_PATH + '/img/svg/kucoin.svg',
    link: 'https://kucoin.tt.tools/',
    show: false,
  },
  {
    name: 'tmm',
    logo: BUILD_PUBLIC_PATH + '/img/svg/tmm.svg',
    link: 'https://tmm.cvizor.com/',
    show: true,
  },
]
