<template>
  <div class="h-100 d-flex flex-column">
    <h1 class="title">
      {{ $t('aboutUs.title') }}
    </h1>

    <CCard class="ccard mt-2 mb-0 overflow-hidden flex-grow-1">
      <CCardBody class="ccard-body overflow-auto">
        <CRow class="crow position-relative">
          <CCol col="12" xl="5" class="partners-media">
            <CCard color="second-card-bg" class="partner-card shadow-none">
              <CCardBody class="ccard-body">
                <h3 class="partner-title text-white">{{ $t('aboutUs.partners') }}</h3>
                <CRow>
                  <template v-for="(partner, i) in partnersData">
                    <CCol
                      v-if="partner.show"
                      :key="i"
                      col="6"
                      md="3"
                      xl="4"
                      class="partner _mb justify-content-center"
                    >
                      <CLink target="_blank" :href="partner.link">
                        <span v-if="partner.svg" style="color: var(--white)" v-html="partner.logo.icon" />
                        <img
                          v-else
                          :src="partner.logo"
                          :alt="partner.name"
                        >
                      </CLink>
                    </CCol>
                  </template>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard color="second-card-bg" class="media-card shadow-none">
              <CCardBody class="ccard-body">
                <h3 class="media-title text-white">{{ $t('aboutUs.media') }}</h3>
                <CRow>
                  <template v-for="(media, i) in mediaData">
                    <CCol
                      v-if="media.show"
                      :key="i"
                      col="2"
                      lg="1"
                      xl="2"
                      class="media-link"
                    >
                      <a
                        :href="media.link || $t(media.linkI18n)"
                        target="_blank"
                      >
                        <span
                          v-if="media.svg"
                          v-html="media.svg"
                        />
                        <img
                          v-else-if="media.logo"
                          :src="media.logo"
                          :alt="media.name"
                        />
                      </a>
                    </CCol>
                  </template>
                </CRow>
              </CCardBody>
            </CCard>
            <CCard color="second-card-bg" class="media-card shadow-none last">
              <CCardBody class="ccard-body">
                <h3 class="media-title text-white mb-2">{{ $t('aboutUs.contacts') }}</h3>
                <CRow>
                  <CCol col="6" class="d-flex flex-column">
                    <a href="mailto:info@cvizor.com" class="mb-1">info@cvizor.com</a>
                    <a href="mailto:support@cvizor.com" class="">support@cvizor.com</a>
                  </CCol>
                  <CCol col="6">
                    <a :href="$t('aboutUs.tgTTToolschat')" class="item mb-2 d-flex">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                            <g>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.61976 14.7779L9.61873 14.7772L9.61862 14.7789L9.27447 19.935C9.76651 19.935 9.99137 19.7161 10.2631 19.4514C10.2707 19.4441 10.2784 19.4366 10.286 19.4292L12.7154 17.0669L17.7717 20.8025C18.7021 21.3159 19.3736 21.0501 19.6053 19.9387L22.924 4.29966C23.2638 2.93741 22.4048 2.31955 21.5147 2.72363L2.0272 10.2379C0.696996 10.7715 0.704751 11.5136 1.78473 11.8443L6.78567 13.4051L18.3634 6.10091C18.9099 5.76948 19.4116 5.94766 18.9998 6.31307L9.61976 14.7779Z"/>
                            </g>
                        </svg>
                      </span>
                      <span class="title">{{ $t('aboutUs.chatLink') }}</span>
                    </a>
                    <a :href="$t('aboutUs.tgTTTools')" class="item mb-2 d-flex">
                      <span>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
                            <g>
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9.61976 14.7779L9.61873 14.7772L9.61862 14.7789L9.27447 19.935C9.76651 19.935 9.99137 19.7161 10.2631 19.4514C10.2707 19.4441 10.2784 19.4366 10.286 19.4292L12.7154 17.0669L17.7717 20.8025C18.7021 21.3159 19.3736 21.0501 19.6053 19.9387L22.924 4.29966C23.2638 2.93741 22.4048 2.31955 21.5147 2.72363L2.0272 10.2379C0.696996 10.7715 0.704751 11.5136 1.78473 11.8443L6.78567 13.4051L18.3634 6.10091C18.9099 5.76948 19.4116 5.94766 18.9998 6.31307L9.61976 14.7779Z"/>
                            </g>
                        </svg>
                      </span>
                      <span class="title">{{ $t('aboutUs.groupLink') }}</span>
                    </a>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </CCol>
          <hr class="divider">
          <CCol col="12" :xl="{size: 6, offset: 1}">
            <CCard color="second-card-bg" class="partner-card form-card shadow-none">
              <CCardBody class="ccard-body">
                <h3 class="partner-title text-white">{{ $t('aboutUs.writeToUs') }}</h3>
                <CForm @submit.prevent="validate">
                  <CRow>
                    <CCol col="6">
                      <CInput
                        :disabled="loadingRequest"
                        v-model="form.name"
                        :placeholder="`${$t('aboutUs.form.name')}`"
                        type="text"
                        class="cinput"
                      />
                    </CCol>
                    <CCol col="6">
                      <CInput
                        :disabled="loadingRequest"
                        v-model="form.tg"
                        :placeholder="`${$t('aboutUs.form.tg')}`"
                        type="text"
                        class="cinput"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CInput
                        :disabled="loadingRequest"
                        v-model="form.email"
                        :placeholder="`${$t('aboutUs.form.email')}`"
                        type="email"
                        class="cinput"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CInput
                        :disabled="loadingRequest"
                        v-model="form.subject"
                        :placeholder="`${$t('aboutUs.form.subject')}`"
                        type="text"
                        class="cinput"
                      />
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <CTextarea
                        :disabled="loadingRequest"
                        v-model="form.question"
                        rows="5"
                        :placeholder="`${$t('aboutUs.form.question')}`"
                        class="cinput"
                      />
                    </CCol>
                  </CRow>
                  <div class="form-footer">
                    <VueRecaptcha
                      v-if="isGuest"
                      ref="recaptcha"
                      size="invisible"
                      :sitekey="RECAPTCHA_SECRET_KEY"
                      @verify="sendFeedback"
                      @expired="onCaptchaExpired"
                    />
                    <CLoadingButtonCustom
                      color="primary"
                      :loading="loadingRequest"
                      :disabled="loadingRequest"
                      type="submit"
                      class="button"
                    >
                      {{ $t('aboutUs.form.send') }}
                    </CLoadingButtonCustom>
                    <div class="info" v-html="$t('aboutUs.form.info')"/>
                  </div>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import partnersData from "@/assets/js/about/partnersData"
import mediaData from "@/assets/js/about/mediaData"
import axios from "axios"
import { VueRecaptcha } from 'vue-recaptcha'
import {mapGetters} from 'vuex';

export default {
  name: "AboutUs",
  components: { VueRecaptcha },
  computed: {
    ...mapGetters({
      isGuest: 'user/isGuest'
    }),
  },
  data() {
    return {
      partnersData,
      mediaData,
      loadingRequest: false,
      form: {
        name: '',
        email: '',
        tg: '',
        subject: '',
        question: '',
        recaptchaToken: ''
      },
      RECAPTCHA_SECRET_KEY: process.env.VUE_APP_RECAPTCHA_SECRET_KEY
    }
  },
  methods: {
    sendFeedback(recaptchaToken) {
      this.loadingRequest = true
      this.form.recaptchaToken = recaptchaToken
      axios.post('/api/v2/send-feedback', this.form)
        .then(({data}) => {
          if (data.status) {
            this.$toastr.success(this.$t('aboutUs.form.response'))
            this.form = {
              name: '',
              email: '',
              tg: '',
              subject: '',
              question: '',
              recaptchaToken: ''
            }
          }
        })
        .catch((er) => {
          console.log(er)
        })
        .finally(() => {
          this.onCaptchaExpired()
          this.loadingRequest = false
        })
    },
    validate () {
      if(this.loadingRequest){
        return
      }
      this.loadingRequest = true
      if(this.isGuest){
        this.$refs.recaptcha.execute()
      }else{
        this.sendFeedback('')
      }
    },
    onCaptchaExpired() {
      if(this.isGuest){
        this.$refs.recaptcha.reset()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  padding: 0 1.25rem;

  @include media-breakpoint-down(lg) {
    font-size: 1.25rem;
  }
  @include media-breakpoint-up(sm) {
    padding: 0 2rem;
  }
  @include media-breakpoint-up(xl) {
    padding: 0;
  }
}

.ccard {
  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1.25rem 1rem;
    }
    .aboutcard {
      margin-bottom: 3rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 2.5rem 1rem 1.5rem;
    }
    .aboutcard {
      margin-bottom: 2.5rem;
    }
  }
  @include media-breakpoint-down(lg) {
    .crow {
      flex-direction: column;
    }
    .aboutcard__title {
      font-size: 1.125rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-body {
      padding: 3rem;
    }
    .aboutcard {
      margin-bottom: 3.5rem;
    }
  }
}

.aboutcard {
  &__title {
    color: #F0F3F5;
  }

  &__subtitle {
    font-size: 1rem;
    margin-bottom: 1.125rem;
  }

  &__link {
    color: #FFC536;
    font-size: 1rem;

    &:hover {
      color: var(--warning);
    }
  }

  &__list {
    padding: 0;
    margin: 0;
    padding-left: 1rem;

    li {
      font-size: 0.75rem;
      margin-bottom: 0.5rem;
    }
  }
}

.partners-media {
  @include media-breakpoint-down(lg) {
    padding: 0;
  }
}

.form-card {
  height: 100%;
}

.partner-card, .media-card {
  @include media-breakpoint-down(xs) {
    margin-bottom: 0.625rem;
  }
  @include media-breakpoint-up(sm) {
    margin-bottom: 1.25rem;
  }
  @include media-breakpoint-up(xl) {
    margin-bottom: 0.5rem;
  }
}

.partner-card, .media-card {
  .media-title {
    margin-bottom: 0.25rem;
  }

  @include media-breakpoint-down(xs) {
    .ccard-body {
      padding: 1.25rem;
    }
    .partner-title {
      margin-bottom: 2.5rem;
    }
  }
  @include media-breakpoint-up(sm) {
    .ccard-body {
      padding: 1.75rem 2rem;
    }
    .partner-title {
      margin-bottom: 3.25rem;
    }
  }
  @include media-breakpoint-down(md) {
    .partner {
      justify-content: center;
    }
  }
  @include media-breakpoint-down(lg) {
    .partner-title, .media-title {
      font-size: 1.25rem;
    }
  }
  @include media-breakpoint-up(xl) {
    .ccard-body {
      padding: 1.75rem;
    }
    .partner-title {
      margin-bottom: 2rem;
    }
  }

  .partner {
    display: flex;
    align-items: center;

    &._mb {
      margin-bottom: 2rem;
    }

    img {
      max-width: 100%;
      max-height: 4rem;
    }
  }
}

.c-icon {
  min-width: 2.75rem;
}

.media-link {
  margin-top: 1rem;
  a {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
  @include media-breakpoint-down(sm) {
    min-width: fit-content;
  }

  /deep/ svg {
    display: block;
    margin: auto;
    width: 2rem;
    height: 2rem;

    path, circle, polygon, g {
      transition: 150ms ease-out;
      fill: var(--color);
    }
    &:hover {
      path, circle, polygon, g{
        transition: 150ms ease-out;
        fill: #3686F6;
      }
    }
  }

  &:hover {
    text-decoration: none;
    path, circle, polygon, g{
      transition: 150ms ease-out;
      fill: #3686F6;
    }
  }
}

.item {
  display: flex;
  gap: 15px;
  align-items: center;

  svg {
    display: block;
    margin: auto;
    width: 1.5rem;
    height: 1.5rem;

    path, circle, polygon, g {
      transition: 150ms ease-out;
      fill: var(--color);
    }
    &:hover {
      path, circle, polygon, g{
        transition: 150ms ease-out;
        fill: #3686F6;
      }
    }
  }

  &:hover {
    text-decoration: none;
    path, circle, polygon, g{
      transition: 150ms ease-out;
      fill: #3686F6;
    }
  }

  .icon {
    width: 25px;
    height: 25px;
    background: var(--primary-contrast);
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 130%;
    color: var(--white);
  }
}

.divider {
  width: 3px;
  height: 100%;
  position: absolute;
  background: var(--dark);
  opacity: 0.2;
  top: 0;
  margin: 0;
  left: 45.8%;

  @media (max-width:1200px) {
    display: none;
  }
}

.cinput /deep/ input, .cinput /deep/ textarea {
  padding: 10px 20px;
  height: auto;
}

.form-footer {
  display: flex;
  gap: 20px;
  align-items: center;
  @media(max-width: 768px) {
    flex-wrap: wrap;
  }

  .button {
    padding: 12px 25px;
    border-radius: 15px;
    flex-shrink: 0;
  }

  .info {
    opacity: 0.6;
    color: var(--dark-contrast);

    /deep/ a {
      color: var(--dark-contrast);
      text-decoration: underline;
      opacity: 1;
    }
  }
}
.last {
  margin-bottom: 2rem;
  @media (min-width: 1200px) {
    margin-bottom: 0;
  }
}
</style>
